import React, {useEffect, useState} from "react";

import { ThemeProvider, Typography} from "@mui/material"
import { ThemeCustom } from "../../styles/Theme";
import Grid from '@mui/material/Grid2'; 

import Card from "../card/card";

const benefit = [
    {title:'Effortless Efficiency', description:'Let us do the heavy lifting. TalentScout quickly processes hundreds (or thousands) of CVs in just a fraction of the time it would take manually.'},
    {title:'Scalable & Seamless', description:'Whether you’re a startup or an enterprise, our solution grows with your needs, making it the perfect fit for businesses of all sizes.'},
    {title:'Pinpoint Accuracy', description:'Get matched with the most relevant candidates, thanks to AI-driven precision that minimizes human error and improves hiring outcomes.'},
    {title:'Streamlined Hiring', description:'No more chaos in your inbox. TalentScout automatically organizes and assigns CVs to the right attributes, ensuring nothing gets lost in the shuffle.'},
    {title:'Customizable Workflows', description:'Tailor TalentScout to suit your recruitment process, ensuring a seamless integration with your existing systems and methods.'},
    {title:'Maximize ROI', description:'Boost your hiring efficiency while reducing time-to-hire and operational costs – maximizing your return on investment.'},
]

const BenefitSection = () => {

    return(
        <ThemeProvider theme={ThemeCustom}>
            <div className="mb-[7rem]">
                <div className="mb-16">
                    <Typography component={'div'} variant="textheader2" className="lg:max-w-[60%] max-w-[80%] ">
                        Save Time. Enhance Accuracy. Maximize ROI.
                    </Typography>
                    <Typography component={'div'} variant="text20" className="text-gray mt-6 lg:max-w-[50%] max-w-[70%] ">
                        Supercharge your recruitment process and find the right talent effortlessly, improve decision-making, and get the most out of your resources.
                    </Typography>
                </div>
                <div>
                    <Grid container spacing={3}>
                        {benefit && benefit.map((item, i)=>(
                            <Grid size={{ md: 6, lg: 4}} key={i}>
                                <Card
                                    title={item.title}
                                    description={item.description}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </div>
        </ThemeProvider>
    )
}

export default BenefitSection;