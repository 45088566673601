import React, {useEffect, useState, useRef} from "react";
import ReactPlayer from 'react-player'

import { ThemeProvider, Typography} from "@mui/material"
import { ThemeCustom } from "../../styles/Theme";

import videoImage from '../../assets/screen-preview.svg'

const VideoSection = () => {

    const [showVideo, setShowVideo] = useState(false);
    const videoRef = useRef(null);

    const handleClick = () => {
        setShowVideo(true);  // Show the video
    };

    return(
        <ThemeProvider theme={ThemeCustom}>
            <div className="my-[12rem]">
                <div className="flex justify-center">
                {!showVideo ? (
                    <img 
                        src={videoImage} 
                        alt="TalentScout video" 
                        className="w-full max-w-[60vw] cursor-pointer"
                        onClick={handleClick}
                    />
                ):(
                    <ReactPlayer 
                        url='https://www.youtube-nocookie.com/embed/50W4YeQdnSg?si=DmcpGVNX2WxIgBHQ'
                        controls={true}
                        onEnded={()=>{setShowVideo(false); console.log('end')}}
                       // width={}
                    />

                    // <iframe 
                    //     ref={videoRef} 
                    //     width="560"
                    //     height="315" 
                    //     src="https://www.youtube-nocookie.com/embed/50W4YeQdnSg?si=DmcpGVNX2WxIgBHQ" 
                    //     title="YouTube video player" 
                    //     frameborder="0" 
                    //     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    //     referrerpolicy="strict-origin-when-cross-origin" 
                    //     allowfullscreen
                    //     className="youTubeFrame"
                    // >
                    // </iframe>
                )}    
                </div>
            </div>
        </ThemeProvider>
    )
}

export default VideoSection;