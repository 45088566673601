import React, {useEffect, useState} from "react";

import { ThemeProvider, Typography, Container} from "@mui/material"
import { ThemeCustom } from "../../styles/Theme";

import ButtonMain from "../button/Button_main";

const ActionSection = () => {

    const getContact = () => {
        console.log('getContact')
        const newWindow = window.open('https://forms.gle/4ZpuC6cJ3sATjTkaA', '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    }

    return(
        <ThemeProvider theme={ThemeCustom}>
            <div className="bg-primary action-btn py-[9rem]">
                <Container maxWidth="xl" >
                    <div className="flex flex-col md:flex-row items-start md:justify-between md:items-center">
                        <Typography component={'div'} variant="textheader2" className="xl:max-w-[40%] lg:max-w-[50%] md:max-w-[60%] text-[#5ba3ff]">
                            Ready to See <span className="text-white">TalentScout</span> in Action?
                        </Typography>
                        <div className="md:w-[40%] flex md:justify-center items-center w-full mt-10 md:mt-0">
                            <ButtonMain 
                                variant='secondary' 
                                type='button' 
                                disabled={false} 
                                name='Request a Demo'
                                class='capitalize px-6'
                                onClick={getContact}
                            />
                        </div>
                    </div>
                </Container>
            </div>
        </ThemeProvider>
    )
}

export default ActionSection;