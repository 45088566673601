import React, {useEffect, useState} from "react";

import { ThemeProvider, Typography } from "@mui/material"
import { ThemeCustom } from "../../styles/Theme";

import ButtonMain from "../button/Button_main";

const Hero = () => {

    const getContact = () => {
        console.log('getContact')
        const newWindow = window.open('https://forms.gle/4ZpuC6cJ3sATjTkaA', '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    }

    return(
        <ThemeProvider theme={ThemeCustom}>
            <div className="flex justify-center flex-col items-center mt-[10rem] mb-[7rem]">
                <div className="flex justify-center items-center bg-culturedLight p-1 pr-3 rounded-2xl">
                    <Typography variant="text14" component="div" className="bg-secondary bg-opacity-70 text-white mr-2 px-3 py-1 rounded-3xl">New</Typography>
                    <Typography variant="text14" component="div" className="">Auto-email updates is now live! 🎉</Typography>
                </div>
                <Typography align="center" component={'div'} variant="textheader1" className="max-w-[70%] mt-8">
                    Hire Better, Faster, and Smarter
                </Typography>
                <Typography align="center" component={'div'} variant="text20" className="max-w-[35%] text-gray mt-6">
                    Unlock the Future of Recruitment with AI-Powered Résumé Screening
                </Typography>
                <ButtonMain 
                    variant='secondary' 
                    type='button' 
                    disabled={false} 
                    name='Get In Touch'
                    class='capitalize px-6 mt-8'
                    onClick={getContact}
                />
            </div>
        </ThemeProvider>
    )
}

export default Hero;