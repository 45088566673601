import React, {useEffect, useState} from "react";

import { ThemeProvider, Container } from "@mui/material"
import { ThemeCustom } from "../styles/Theme";

import Navbar from "../components/navbar/Navbar";
import Hero from "../components/hero/Hero"; 
import BenefitSection from "../components/sections/benefitSection";
import ActionSection from "../components/sections/actionSection";
import Footer from "../components/navbar/Footer";
import VideoSection from "../components/sections/videoSection";

const Home = () => {
    return(
        <ThemeProvider theme={ThemeCustom}>
            <Container maxWidth="xl" >
                <Navbar/>
                <Hero/>
                <VideoSection/>
                <BenefitSection/>
            </Container>
            <ActionSection/>
            <Footer/>
        </ThemeProvider>        
    )
}

export default Home;