import React, {useEffect, useState} from "react";
import { useNavigate, useLocation, Link } from 'react-router-dom';
import {ThemeProvider, Container, Typography, Avatar, Chip } from '@mui/material';

import {ThemeMain, ThemeCustom} from '../../styles/Theme';

const Card = (props) => {


    return(
        <ThemeProvider theme={ThemeCustom}>
           <div className="p-10 bg-secondary rounded-[1.25rem] md:min-h-[14rem]">
                <Typography component={'div'} variant="textSubheader1" className="text-white font-semibold mb-5">
                    {props.title}
                </Typography>
                <Typography component={'div'} variant="textBase" className="text-white">
                    {props.description}
                </Typography>
            </div>
        </ThemeProvider>
    )
}

export default Card;